import React from 'react'

import { classNames } from '@/components/shared/classNames'

import styles from './UIList.module.scss'

export interface Props {
  children: React.ReactNode
  className?: string
  liClassName?: string
  mode?: 'vertical' | 'horizontal' | 'hidden'
  prependIcon?: React.ReactNode
  id?: string
}

export const UIList = ({
  className = '',
  liClassName = '',
  mode = 'horizontal',
  children,
  prependIcon = null,
  id
}: Props) => {
  const uuid = generateUuid()

  const iconSection = prependIcon ?? null
  const flexClass = mode === 'horizontal' ? 'flex flex-row' : 'flex flex-col'
  return (
    <ul className={classNames(styles['ui-list'], styles[`ui-list--${mode}`], className, flexClass)} id={id}>
      {React.Children.map(children, (child, i) => (
        <li
          key={`${uuid}-${i}`}
          className={classNames('relative flex items-start gap-2', iconSection ? 'pl-6' : '', liClassName)}
        >
          {iconSection}
          {child}
        </li>
      ))}
    </ul>
  )
}

const generateUuid = () => {
  return Math.floor(Math.random() * 1000000000000000000)
}
