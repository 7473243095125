'use client'
import { v4 as uuidv4 } from 'uuid'
import { Icon } from '@/components/icons/Icon'
import { classNames } from '@/components/shared/classNames'

import styles from './MenuItem.module.scss'
import { UIList } from '@/components/UIList/UIList'
import { MenuDropDownItem } from './MenuDropDownItem'
import { useState } from 'react'

export interface MenuItemData {
  id?: string
  parentID?: string
  label?: string | React.ReactNode | JSX.Element
  url?: string
  className?: string
  submenuList?: MenuItemData[]
}

export const MenuItem = ({ label, url = '', className, submenuList }: MenuItemData) => {
  const hasSubmenu = Array.isArray(submenuList) && submenuList.length
  const someChildHasSubmenu = Array.isArray(submenuList) && submenuList.some(item => Array.isArray(item.submenuList))
  const [isActive, setIsActive] = useState('')

  const handleMouseOver = () => {
    setIsActive('active')
  }

  const handleMouseOut = () => {
    setIsActive('')
  }
  return (
    <div
      className={classNames(className, styles.wrapper, styles[isActive])}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {url !== '' ? (
        <a href={url} className={styles['menu-item']}>
          <div className={classNames(className, styles.label)}>{label}</div>
          {hasSubmenu && <Icon name="chevronDown" className={classNames('menu-chevron', styles.icon)} />}
        </a>
      ) : (
        <div className={styles['menu-item']}>
          <div className={classNames(className, styles.label)}>{label}</div>
          {hasSubmenu && <Icon name="chevronDown" className={classNames('menu-chevron', styles.icon)} />}
        </div>
      )}
      {hasSubmenu && (
        <UIList
          mode="vertical"
          className={classNames(
            styles['submenu-container'],
            'fade-appear absolute right-0 top-full flex-1 shadow-md rounded-bl-lg w-[max-content] bg-white pl-1 py-1 cursor-default',
            !someChildHasSubmenu ? 'rounded-br-lg pr-1' : ''
          )}
          liClassName="w-full"
        >
          {submenuList.map((item, index) => (
            <MenuDropDownItem
              key={index}
              label={item.label}
              url={item.url}
              js-ref={uuidv4()}
              className="w-full"
              submenuList={item.submenuList}
            />
          ))}
        </UIList>
      )}
    </div>
  )
}
