'use client'
import Link from 'next/link'
import { Icon } from '@/components/icons/Icon'
import { classNames } from '@/components/shared/classNames'
import { v4 as uuidv4 } from 'uuid'
import styles from './MenuDropDownItem.module.scss'
import { UIList } from '../UIList/UIList'
import { useState } from 'react'

export interface MenuDropDownItemType {
  label?: string | React.ReactNode
  url?: string
  className?: string
  submenuList?: MenuDropDownItemType[]
}

export const MenuDropDownItem = ({ label, url = '', className, submenuList }: MenuDropDownItemType) => {
  const hasSubmenu = Array.isArray(submenuList) && submenuList.length > 0
  const Tag = url ? Link : 'div'
  const [isActive, setIsActive] = useState('')

  const handleMouseOver = () => {
    setIsActive('active')
  }

  const handleMouseOut = () => {
    setIsActive('')
  }

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={classNames(styles.wrapper, styles[isActive], className)}
    >
      {url !== '' ? (
        <a href={url} className={styles['menu-item']}>
          <div className={classNames(styles.label)}>{label}</div>
          {hasSubmenu && <Icon name="chevronRight" className={classNames('menu-chevron', styles.icon)} />}
        </a>
      ) : (
        <div className={styles['menu-item']}>
          <div className={classNames(styles.label)}>{label}</div>
          {hasSubmenu && <Icon name="chevronRight" className={classNames('menu-chevron', styles.icon)} />}
        </div>
      )}
      {hasSubmenu && (
        <div
          className={classNames('px-1 fade-appear absolute left-[calc(100%-5px)] top-0', styles['submenu-container'])}
        >
          <UIList
            mode="vertical"
            className={classNames('flex-1 shadow-md rounded-lg w-[max-content] min-h-full bg-white cursor-default')}
            liClassName="w-full max-w-[350px]"
          >
            {submenuList.map((item, index) => (
              <MenuDropDownItem
                key={index}
                label={item.label}
                url={item.url}
                js-ref={uuidv4()}
                className="w-full"
                submenuList={item.submenuList}
              />
            ))}
          </UIList>
        </div>
      )}
    </div>
  )
}
