import React from 'react'
import { UIList } from '../UIList/UIList'
import { MenuItem, MenuItemData } from './MenuItem'

interface Props {
  menuItems: MenuItemData[]
  mainAction?: React.ReactNode
}

const MenuWeb = ({ menuItems, mainAction = undefined }: Props) => {
  return (
    <UIList liClassName="h-full" className="gap-8 items-center min-w-[1024] h-14">
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          label={item.label}
          url={item.url}
          submenuList={item.submenuList}
          className="z-10 text-sm 2xl:text-base"
        />
      ))}
      {mainAction && <div className="h-full flex items-center">{mainAction}</div>}
    </UIList>
  )
}

export default MenuWeb
