'use client'
import { Container } from '@/components/objects/Container'
import Link from 'next/link'
import LogoImage from './LogoImage'
import { MenuItemData } from './MenuItem'
import { MenuMobile } from './MenuMobile'
import MenuWeb from './MenuWeb'
import { ReactElement } from 'react'
import { classNames } from '../shared/classNames'

const replaceMenuItemsLabelsByFragments = (menuItems: MenuItemData[] = []): MenuItemData[] => {
  return menuItems.map(item => {
    if (item.submenuList) {
      const label =
        typeof item.label === 'string' ? <span dangerouslySetInnerHTML={{ __html: String(item.label) }} /> : item.label
      return {
        ...item,
        label,
        submenuList: replaceMenuItemsLabelsByFragments(item.submenuList)
      }
    }

    const label =
      typeof item.label === 'string' ? <span dangerouslySetInnerHTML={{ __html: String(item.label) }} /> : item.label
    return {
      ...item,
      label
    }
  })
}

interface Props {
  className?: string
  logoUrl?: string
  menuItemsWeb: MenuItemData[]
  menuItemsMobile: MenuItemData[]
  mainActionWeb?: ReactElement
  mainActionMobile?: ReactElement
}

const NavBar = async ({
  className = '',
  logoUrl = '/',
  menuItemsWeb,
  menuItemsMobile,
  mainActionWeb,
  mainActionMobile
}: Props) => {
  const hiddeBrevoChat = () => {
    const brevoConversations = document.getElementById('brevo-conversations')
    if (brevoConversations) {
      brevoConversations.style.display = 'none'
    }
  }
  const showBrevoChat = () => {
    const brevoConversations = document.getElementById('brevo-conversations')
    if (brevoConversations) {
      brevoConversations.style.display = 'block'
    }
  }
  return (
    <header className={classNames('relative py-3 sm:py-0 lg:py-1 xl:py-2 z-[2147483649]', className)}>
      <Container type="big" className="flex flex-wrap justify-between items-center relative">
        <Link href={logoUrl} className="flex justify-center items-center lg:mx-auto min-[1366px]:mx-0">
          <LogoImage />
        </Link>
        <div className="flex lg:hidden gap-5">
          <MenuMobile menuItems={replaceMenuItemsLabelsByFragments(menuItemsMobile)} mainAction={mainActionMobile} />
        </div>
        <div
          className="hidden lg:inline min-w-[1024] mx-auto min-[1366px]:mx-0"
          onMouseEnter={hiddeBrevoChat}
          onMouseLeave={showBrevoChat}
        >
          <MenuWeb menuItems={replaceMenuItemsLabelsByFragments(menuItemsWeb)} mainAction={mainActionWeb} />
        </div>
      </Container>
    </header>
  )
}

export default NavBar
