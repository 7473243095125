import styles from './Container.module.scss'
import '@/styles/_grid.scss'
import { classNames } from '@/components/shared/classNames'

export interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  type?: 'default' | 'big' | 'full' | 'full-left' | 'full-right' | 'flex' | 'flex-row' | 'hidden' | 'blog-article'
  tag?: 'div' | 'section' | 'ul' | 'ol' | 'footer' | 'header' | 'main' | 'nav'
  ariaLabel?: string
  ariaLabelledby?: string
  className?: string
}

export const Container = ({
  className,
  tag = 'div',
  type = 'default',
  ariaLabel,
  ariaLabelledby,
  children
}: ContainerProps) => {
  const Tag = tag

  return (
    <Tag
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      className={classNames('flex', styles.container, styles[`container--${type}`], className)}
    >
      {children}
    </Tag>
  )
}
