import Image from 'next/image'

interface Props {
  variant?: 'default' | 'short' | 'fullWhite' | 'shortWhite'
  className?: string
}

const VARIANT = {
  default: (
    <>
      <Image
        src="/assets/images/logo/logo-globallynx-making-it-better.svg"
        alt="Logotipo Global Lynx Making IT Better"
        width="300"
        height="80"
        className="hidden sm:block min-w-[200px] h-auto aspect-[300/80]"
        priority={true}
      />
      <Image
        src="/assets/images/logo/logo-globallynx.svg"
        alt="Logotipo Global Lynx"
        width="120"
        height="67"
        className="block sm:hidden"
        priority={true}
      />
    </>
  ),
  fullWhite: (
    // logo-globallynx-making-it-better-white
    <Image
      src="/assets/images/logo/logo-globallynx-making-it-better-white.svg"
      alt="Logotipo Global Lynx Making IT Better"
      width="300"
      height="80"
      className="hidden sm:block min-w-[200px] h-auto aspect-[300/80]"
      priority={true}
    />
  ),
  shortWhite: (
    <Image
      src="/assets/images/logo/logo-globallynx-white.svg"
      alt="Logotipo Global Lynx"
      width="120"
      height="67"
      className="block"
      priority={true}
    />
  ),
  short: (
    <Image
      src="/assets/images/logo/logo-globallynx.svg"
      alt="Logotipo Global Lynx"
      width="120"
      height="67"
      className="block"
      priority={true}
    />
  )
}

const LogoImage = ({ variant = 'default', className }: Props) => {
  return <div className={className}>{VARIANT[variant]}</div>
}

export default LogoImage
