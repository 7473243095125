'use client'
import { Icon } from '@/components/icons/Icon'

import { Button } from '../buttons/Button'
import styles from './MenuMobile.module.scss'
import { Col } from '../objects/Col'
import Link from 'next/link'
import React, { useState, useEffect, ReactElement } from 'react'
import { classNames } from '../shared/classNames'
import { Row } from '../objects/Row'

export interface MenuItemData {
  id?: string
  parentID?: string
  label?: string | React.ReactNode | JSX.Element
  url?: string
  className?: string
  submenuList?: MenuItemData[]
}

export interface Props {
  menuItems: MenuItemData[]
  mainAction?: ReactElement
}

const MenuLayerWrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div className={classNames('fixed inset-0 bg-black bg-opacity-50 z-50 w-full h-full', className)}>{children}</div>
  )
}

export const MenuMobile = ({ menuItems, mainAction = undefined }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isInLevelOne, setIsInLevelOne] = useState(true)
  const [isInLevelTwo, setIsInLevelTwo] = useState(false)
  const [isInLevelThree, setIsInLevelThree] = useState(false)
  const [isInLevelFour, setIsInLevelFour] = useState(false)
  const [layerTwoItems, setLayerTwoItems] = useState<MenuItemData[]>([])
  const [layerThreeItems, setLayerThreeItems] = useState<MenuItemData[]>([])
  const [layerFourItems, setLayerFourItems] = useState<MenuItemData[]>([])

  const closeMenu = () => {
    setLayerTwoItems([])
    setLayerThreeItems([])
    setLayerFourItems([])
    setMenuOpen(false)
    showBrevoChat()
  }
  // Si no hay elementos en layerTwoItems, entonces estamos en el primer nivel
  useEffect(() => {
    if (layerTwoItems.length === 0) {
      setIsInLevelOne(true)
    } else {
      setIsInLevelOne(false)
    }
  }, [layerTwoItems])
  // Si hay elementos en layerTwoItems y no hay elementos en layerThreeItems ni en layerFourItems, entonces estamos en el segundo nivel
  useEffect(() => {
    if (layerTwoItems.length > 0 && layerThreeItems.length === 0 && layerFourItems.length === 0) {
      setIsInLevelTwo(true)
    } else {
      setIsInLevelTwo(false)
    }
  }, [layerTwoItems, layerThreeItems, layerFourItems])
  // Si hay elementos en layerThreeItems y no hay elementos en layerFourItems, entonces estamos en el tercer nivel
  useEffect(() => {
    if (layerThreeItems.length > 0 && layerFourItems.length === 0) {
      setIsInLevelThree(true)
    } else {
      setIsInLevelThree(false)
    }
  }, [layerThreeItems, layerFourItems])
  // Si hay elementos en layerFourItems, entonces estamos en el cuarto nivel
  useEffect(() => {
    if (layerFourItems.length > 0) {
      setIsInLevelFour(true)
    } else {
      setIsInLevelFour(false)
    }
  }, [layerFourItems])

  const goBackwards = () => {
    if (isInLevelFour) {
      setLayerFourItems([])
    } else if (isInLevelThree) {
      setLayerThreeItems([])
    } else if (isInLevelTwo) {
      setLayerTwoItems([])
    } else {
      closeMenu()
    }
  }

  const subItemsIcon = <Icon name="chevronRight" className="w-4 h-4 absolute right-3" />
  const hiddeBrevoChat = () => {
    const brevoConversations = document.getElementById('brevo-conversations')
    if (brevoConversations) {
      brevoConversations.style.display = 'none'
    }
  }
  const showBrevoChat = () => {
    const brevoConversations = document.getElementById('brevo-conversations')
    if (brevoConversations) {
      brevoConversations.style.display = 'block'
    }
  }

  const extraProps = {
    onClick: () => {
      closeMenu()
    }
  }
  const MainActionElement = mainAction ? React.cloneElement(mainAction, extraProps) : undefined

  return (
    <>
      <Button
        theme="menu"
        size="noSize"
        className="full-center"
        onClick={() => {
          hiddeBrevoChat()
          setMenuOpen(true)
        }}
        aria-label="Abrir menú"
      >
        <Icon name="menu" className="w-14 h-14" />
      </Button>
      <MenuLayerWrapper
        className={classNames(
          styles['menu-wrapper'],
          menuOpen ? styles['menu-wrapper--active'] : '',
          menuOpen ? styles['fade-animation'] : ''
        )}
      >
        <div
          className="absolute top-0 left-0 z-0 flex flex-col h-full w-full items-end justify-center"
          onClick={closeMenu}
        >
          <Icon name="close" className="w-8 h-8 text-white mr-5" />
        </div>
        <Col
          className={classNames(
            'fixed inset-0 bg-white z-50 w-10/12 h-full',
            styles['menu-layer'],
            styles['slide-from-left-animation'],
            menuOpen ? styles['slide-from-left-animation--active'] : ''
          )}
        >
          <Row className="items-center justify-center h-[10vh]">
            <div
              className="flex items-center text-blue-darkest text-2xl font-bold underline underline-offset-8 decoration-blue-light absolute left-5 top-5 group"
              onClick={goBackwards}
            >
              <Icon name="arrowLeft" className="w-5 h-5 text-blue-darkest group-hover:-translate-x-1 duration-300" />
            </div>
            {MainActionElement}
          </Row>
          <Col className="max-h-[90vh] h-[90vh] overflow-y-auto px-3 pt-5">
            {isInLevelOne && (
              <div
                id="layerOne"
                className={classNames(
                  styles['items-wrapper'],
                  styles['slide-from-left-animation'],
                  isInLevelOne ? styles['slide-from-left-animation--active'] : ''
                )}
              >
                {menuItems.map((menuItem, index) => {
                  return menuItem.submenuList && menuItem.submenuList?.length > 0 ? (
                    <div
                      key={index}
                      className={classNames(styles['menu-item'])}
                      onClick={() => {
                        setLayerTwoItems(menuItem.submenuList ?? [])
                      }}
                    >
                      {menuItem.label} {subItemsIcon}
                    </div>
                  ) : menuItem.url === '/blog' ? (
                    <a
                      href={menuItem.url}
                      key={index}
                      className={classNames(styles['menu-item'], 'link')}
                      onClick={closeMenu}
                    >
                      {menuItem.label}
                    </a>
                  ) : (
                    <Link href={String(menuItem.url)} key={index} legacyBehavior>
                      <a className={classNames(styles['menu-item'], 'link')} onClick={closeMenu}>
                        {menuItem.label}
                      </a>
                    </Link>
                  )
                })}
              </div>
            )}
            {isInLevelTwo && (
              <div
                id="layerTwo"
                className={classNames(
                  styles['items-wrapper'],
                  styles['slide-from-left-animation'],
                  isInLevelTwo ? styles['slide-from-left-animation--active'] : '',
                  'absolute'
                )}
              >
                {layerTwoItems.map((menuItem, index) => {
                  return menuItem.submenuList && menuItem.submenuList?.length > 0 ? (
                    <div
                      key={index}
                      className={classNames(styles['menu-item'])}
                      onClick={() => {
                        setLayerThreeItems(menuItem.submenuList ?? [])
                      }}
                    >
                      {menuItem.label} {subItemsIcon}
                    </div>
                  ) : (
                    <Link href={String(menuItem.url)} key={index} legacyBehavior>
                      <a className={classNames(styles['menu-item'], 'link')} onClick={closeMenu}>
                        {menuItem.label}
                      </a>
                    </Link>
                  )
                })}
              </div>
            )}
            {isInLevelThree && (
              <div
                id="layerThree"
                className={classNames(
                  styles['items-wrapper'],
                  styles['slide-from-left-animation'],
                  isInLevelThree ? styles['slide-from-left-animation--active'] : '',
                  'absolute'
                )}
              >
                {layerThreeItems.map((menuItem, index) => {
                  return menuItem.submenuList && menuItem.submenuList?.length > 0 ? (
                    <div
                      key={index}
                      className={classNames(styles['menu-item'])}
                      onClick={() => {
                        setLayerFourItems(menuItem.submenuList ?? [])
                      }}
                    >
                      {menuItem.label} {subItemsIcon}
                    </div>
                  ) : (
                    <Link href={String(menuItem.url)} key={index} legacyBehavior>
                      <a className={classNames(styles['menu-item'], 'link')} onClick={closeMenu}>
                        {menuItem.label}
                      </a>
                    </Link>
                  )
                })}
              </div>
            )}
            {isInLevelFour && (
              <div
                id="layerFour"
                className={classNames(
                  styles['items-wrapper'],
                  styles['slide-from-left-animation'],
                  isInLevelFour ? styles['slide-from-left-animation--active'] : '',
                  'absolute'
                )}
              >
                {layerFourItems.map((menuItem, index) => {
                  return menuItem.submenuList && menuItem.submenuList?.length > 0 ? (
                    <div key={index} className={classNames(styles['menu-item'])}>
                      {menuItem.label}
                    </div>
                  ) : (
                    <Link href={String(menuItem.url)} key={index} legacyBehavior>
                      <a className={classNames(styles['menu-item'], 'link')} onClick={closeMenu}>
                        {menuItem.label}
                      </a>
                    </Link>
                  )
                })}
              </div>
            )}
          </Col>
        </Col>
      </MenuLayerWrapper>
    </>
  )
}
